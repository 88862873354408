<template>
  <v-footer app inset padless absolute width="auto">
    <v-card class="flex" flat tile>
      <v-card-title class="grey lighten-4">
        <div :class="[`text-caption`, active && `mb-4`]">
          {{ new Date().getFullYear() }} Opiny
        </div>

        <v-spacer></v-spacer>

        <v-btn v-on:click="launchPage()" v-for="icon in icons" :key="icon" class="mx-4" light icon>
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",

  data: () => ({
    icons: ["mdi-instagram"],
  }),
  methods: {
    launchPage: function() {
      window.open('https://www.instagram.com/opiny_app', '_blank');
    }
  }
};
</script>
