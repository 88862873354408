<template>
  <v-container>
    <div v-html="data"></div>
    <div class="changed_at">{{ changed_at }}</div>
  </v-container>
</template>

<script>
import axios from 'axios'
import store from '../plugins/vuex';
import { marked } from 'marked';

export default {
  name: "Markdown",
  data() {
    return {
      changed_at: null,
      data: null,
    }
  },
  props: {
    id: {type: String, required: true},
  },
  // eslint-disable-next-line
  beforeRouteEnter(to, from, next) {
    next(vm => vm.init());
  },
  // eslint-disable-next-line
  beforeRouteUpdate(to, from, next) {
    this.init();
    next();
  },
  methods: {
    init() {
      this.clearData();
      axios.post("/store/md", { 'id': this.$props.id }).then(response => {
        this.setData(response.data);
      }).catch(error => {
        console.error("error", error);
      });
    },
    clearData() {
      this.changed_at = null
      this.data = null
    },
    setData(md) {
      let seconds = md.changed_at._seconds;
      let date = new Date(seconds * 1000);
      this.changed_at = date.toLocaleDateString();
      switch (store.state.langCode) {
        case 'tr':
          this.data = marked(md.data.tr);
          break;
        default:
          this.data = marked(md.data.en);
          break;
      }
    }
  },
};
</script>
